import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nicht gefunden" />
    <div className="container">
      <br />
      <h1>Nicht gefunden</h1>
      <p>Die angeforderte Seite existiert leider nicht</p>
    </div>
  </Layout>
)

export default NotFoundPage
